<script>
    import Layout from "@/router/layouts/template-pemohon";
    import appConfig from "@/app.config";
    import PageHeader from "@/components/page-header";
    import axios from "axios";
    import Swal from "sweetalert2"
    import moment from 'moment';
    /**
     * Dashboard Component
     */
    export default {
        page: {
            title: "Daftar Id Izin",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        components: {
            Layout,
            PageHeader,
        },
        data() {
            return {
                title: "Daftar Id Izin",
                items: [
                    {
                        text: "Daftar Id Izin OSS",
                        active: true,
                    },
                    
                ],
                // variable Page Table
                loadingTable: true,
                dataTable: [],
                pagingTable: [],
                showDataToTable: '',
                showDataFromTable: '',
                totalDataTable: '',
                currentTablePage: '',
                searchDataTable: '',
                emptyDataTable: true,
            };
        },
        mounted(){
            // Load data awal banget
            let self = this;
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "oss/all-daftar-id-izin",
                params: {
                    nib: localStorage.session_nib
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config).then(function (response) {
                var response_data = response.data;
                var response_data_fix = response_data.data[0];
                if(response_data.meta.code == 200){
                    self.loadingTable = false;
                    if(response_data_fix.data){
                        self.emptyDataTable = false;
                    }else{
                        self.emptyDataTable = true;
                    }
                    console.log(response_data_fix.data);
                    self.dataTable = response_data_fix.data;
                    self.pagingTable = response_data_fix.links;
					self.showDataToTable = response_data_fix.to;
					self.showDataFromTable = response_data_fix.from;
					self.totalDataTable = response_data_fix.total;
					self.currentTablePage = response_data_fix.current_page;
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response_data_fix.data.message
                    });
                }
            }).catch(function (error) {
                console.log(error);
            });
        },
        methods:{
            fullDate(date){
                return moment(date).format('DD MMMM YYYY');
            },
            toPage(url) {
                let self = this;
				self.loadingTable = true;
                self.emptyDataTable = true;
                var config = {
                    method: "get",
                    url: url,
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                };
				axios(config).then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if(response_data.meta.code == 200){
                        self.loadingTable = false;
                        if(response_data_fix.data){
                            self.emptyDataTable = false;
                        }else{
                            self.emptyDataTable = true;
                        }
                        
                        self.dataTable = response_data_fix.data;
                        self.pagingTable = response_data_fix.links;
                        self.showDataToTable = response_data_fix.to;
                        self.showDataFromTable = response_data_fix.from;
                        self.totalDataTable = response_data_fix.total;
                        self.currentTablePage = response_data_fix.current_page;
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: response_data_fix.data.message
                        });
                    }
                }).catch(function (error) {
                    console.log(error);
                });
			},
            searchTable(){
                let self = this;
                self.loadingTable = true;
                self.emptyDataTable = false;
                var config = {
                    method: "get",
                    url: process.env.VUE_APP_BACKEND_URL_VERSION + "oss/all-daftar-id-izin",
                    params: {
                        nib: localStorage.session_nib,
                        search: self.searchDataTable
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                };
                axios(config).then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if(response_data.meta.code == 200){
                        self.loadingTable = false;
                        self.dataTable = response_data_fix.data;
                        if(self.dataTable){
                            self.emptyDataTable = false;
                        }else{
                            self.emptyDataTable = true;
                        }

                        self.pagingTable = response_data_fix.links;
                        self.showDataToTable = response_data_fix.to;
                        self.showDataFromTable = response_data_fix.from;
                        self.totalDataTable = response_data_fix.total;
                        self.currentTablePage = response_data_fix.current_page;
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: response_data_fix.data.message
                        });
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            },
            checkKodeIzin(row_perusahaan_res){
                let self = this;
                var perusahaan_res = self.dataTable[row_perusahaan_res];
                if(perusahaan_res.permohonan_id){
                    Swal.fire({
                        icon: 'error',
                        title: 'Gagal',
                        text: "Id Izin Telah Digunakan"
                    });
                }
                else{
                    if(perusahaan_res.perizinansub.is_konfig_form == 't' && perusahaan_res.perizinansub.is_konfig_dokumen == 't' && perusahaan_res.perizinansub.is_konfig_matrix == 't'){
                        self.$router.push({ 
                            name: "add-permohonan",
                            query:{
                                perizinan_id : perusahaan_res.perizinansub.mps_mp_id, 
                                perizinan_sub_id : perusahaan_res.perizinansub.mps_id,
                                kode_izin : perusahaan_res.kode_izin,
                                id_izin : perusahaan_res.idIzin,
                            }
                        });
                    }
                    else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: "Perizinan Belum Tersedia"
                        });
                    }
                }
                console.log(perusahaan_res);
            }
                    
                
            
        }
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="alert alert-info" role="alert">
                                    <div class="row">
                                        <div class="col-md-8 text-start" style="margin-top: 10px;">
                                            Pengumuman : Apabila id izin tidak muncul, klik tombol refresh atau tunggu keesokan harinya.
                                        </div>
                                        <div class="col-md-4 text-end">
                                            <button type="button" class="btn btn-primary"><i class="fas fa-sync-alt"></i> Refresh</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <br/>
                                <div class="row">
                                    <div class="col-md-9">
                                        <!-- Untuk Filter Data -->
                                        &nbsp;
                                    </div>
                                    <div class="col-md-3"> 
                                        <input type="text" class="form-control" id="searchTable" v-on:change="searchTable" v-model="searchDataTable" placeholder="Cari Data ...">
                                    </div>
                                </div>
                                <br/>
                            </div>
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table mb-0 table-bordered table-condensed table-hover">
                                        <thead class="bg-dark text-center text-white">
                                            <tr>
                                                <th>ID Izin / ID Proyek / KBLI</th>
                                                <th>Perusahaan / Perizinan</th>
                                                <th>Tanggal</th>
                                                <th style="width: 175px;">Status</th>
                                                <th style="width: 175px;">Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="loadingTable">
                                                <td colspan="5"><i class="fas fa-spinner fa-spin"></i> Loading</td>
                                            </tr> 
                                            <tr v-else-if="emptyDataTable">
                                                <td colspan="5"><i class="fas fa-times"></i> Data Tidak Tersedia</td>
                                            </tr>
                                            <tr v-for="(row_data, key_data) in dataTable" :key="key_data" v-else>
                                                <td>
                                                    <table class="table mb-0 table-condensed">
                                                        <tr>
                                                            <td>{{ row_data.idIzin }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ row_data.idProyek }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ row_data.namaKbli }}</td>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td>
                                                    <table class="table mb-0 table-condensed">
                                                        <tr>
                                                            <td>{{ row_data.perusahaan.mpr_nama }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ row_data.perizinan.mp_nama }}</td>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td class="text-center">
                                                    {{ row_data.tanggal }}
                                                </td>
                                                <td class="text-center">
                                                    <div class='badge badge-pill badge-soft-danger font-size-12'>BELUM ADA PENGAJUAN</div>
                                                </td>
                                                <td class="text-center">
                                                    <button type="button" class="btn btn-warning btn-sm" v-on:click="checkKodeIzin(key_data)">Ajukan</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="pt-4">
                                    Show {{ showDataFromTable }}-{{ showDataToTable }} From {{ totalDataTable }}
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div style="float: right !important;">
                                    <nav aria-label="pagination" class="mt-3">
                                        <ul class="pagination justify-content-start">
                                            <li v-for="(page, key_page) in pagingTable" v-bind:class="{ 'active': page.active }" :key="key_page" class="page-item">
                                                <div v-on:click="toPage(page.url)" class="page-link c-page-link-number"> 
                                                    <div v-html="page.label"></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
