<script>
import {
    layoutComputed
} from "@/state/helpers";
import Horizontal from "./horizontal";

export default {
    components: {

        Horizontal,
    },
    data() {
        return {};
    },
    computed: {
        ...layoutComputed,
    },
    methods: {},
    mounted() {
        // document.querySelector("html").setAttribute('dir', 'rtl');
    }
};
</script>

<template>
<div>
    <Horizontal>
        <slot />
    </Horizontal>
</div>
</template>
